import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm, QuoteFormPageQuery } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<QuoteForm>["setValue"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
  formData: UseFormTools<QuoteForm>["formData"];
}

export const CarAge: React.FC<PropTypes> = ({
  setValue,
  commonValues,
  formData,
}) => {
  const [fieldValue, setFieldValue] = React.useState("");
  const assetAgeBands = commonValues.assetAgeBands;
  const isAssetFound = formData.assetFound === "Yes";

  React.useEffect(() => {
    if (fieldValue !== "") {
      setValue("assetAgeEstimate", fieldValue);
      setFieldValue("");

      if (isAssetFound) {
        navigate("/quote/car-seller-type");
        return;
      }

      if (
        +fieldValue >
        commonValues.flexibleLoanPurposes.rateEstimate.securedAssetMaxAge
      ) {
        navigate("/quote/loan-amount");
      } else {
        navigate("/quote/asset-security");
      }
    }
  }, [
    commonValues.flexibleLoanPurposes.rateEstimate.securedAssetMaxAge,
    fieldValue,
    setValue,
    isAssetFound,
  ]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CAR_AGE);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading
        title={
          isAssetFound
            ? "What's the age of the vehicle?"
            : "What's the ideal age of the vehicle you’re looking for?"
        }
      />

      <Field
        type="radio-list"
        label=""
        options={assetAgeBands.map((ageBand) => ({
          label: ageBand.label,
          value: ageBand.assumedAssetAge,
        }))}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value);
        }}
      />
    </div>
  );
};
